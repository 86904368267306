/*============= RESETS =============*/

/*-- iPhone X Remove Gutters --*/

html {
	padding: env(safe-area-inset);
	/* SBAdmin Template*/
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*-- Prevent Horizontal Scrolling & Font Style --*/

html,
body {
	overflow-x: hidden;
	font-family: 'Lato', sans-serif;
	color: #505962;
}

/* #fundoAuth {
	height: auto;
	margin: 0;
} */

/*--- Nav Scrolling Offset (Usado para garantir que o conteudo começe exatamente após a barra de nagevação)--*/

.offset:before {
	content: '';
	height: 4rem;
	display: block;
	margin-top: -4rem;
}

/*--- Extra Bootstrap Column Padding --*/

/* [class*='col-'] {
	padding: 0.85rem;
} */

.lista-height {
	height: 47vh;
	/* padding: 1.5rem; */
}

/*============= NAVIGATION =============*/

.navbar {
	font-weight: 700;
	letter-spacing: 0.1rem;
	transition: background-color 0.5s ease;
}

.navbar-nav .nav-link.active,
.navbar-nav ,
/*.nav-link:hover .text-primary:hover, */
.text-primary.active {
	color: #ff5a5f !important;
}

.custom-toggler-icon {
	color: white;
	font-size: 1.6rem;
}

.custom-toggler-icon-primary {
	color: #17bebb;
	font-size: 1.6rem;
}

/*-- Remove Button Outline --*/

button:focus,
a:focus,
a.btn:focus {
	outline: 0 !important;
	-webkit-appearance: none;
	box-shadow: none;
}

button,
a,
a.btn {
	transition: all 0.6s ease;
}

.btn-secondary:hover {
	border-color: #fa7074 !important;
	background-color: #fa7074 !important;
	color: white !important;
}

.btn-primary:hover {
	border-color: #74d7d7 !important;
	background-color: #74d7d7 !important;
	color: white !important;
}

/* - Usado em tela cheia- */

.navbar.solid {
	/* background-color: #17bebb; */
	background-color: white;
	transition: background-color 1s ease;
}

/* - Usado em tela Mobile- */

.navbar.solid-toggle {
	background-color: #17bebb;
	/* background-color: white; */
	transition: background-color 1s ease;
}

/* ============Comum a todas as seções================== */

/* .jumbotron {
	background-color: #f8fbfb !important;
} */

.logo-beganinha {
	width: 550px;
	height: 180px;
}

.logo-startman {
	width: 180px;
	height: 180px;
}

.logo-beganinha-pequeno {
	width: 150px;
	height: 60px;
}

.logo-be {
	width: 50px;
	height: 50px;
}

h3.heading {
	font-weight: 700;
	font-size: 1.9rem;
	text-align: center;
	margin-bottom: 1.9rem;
}

h4.heading {
	font-weight: 700;
	font-size: 1.5rem;
	text-align: center;
	margin-bottom: 1.9rem;
}

.heading-underline {
	width: 3rem;
	height: 0.2rem;
	background-color: #1ebba3;
	margin: 0 auto 2rem;
}

/* .margin-nao-encontrado-page {
	margin-top: 15%;
	margin-bottom: 15%;
} */
.margin-nao-encontrado-page {
	margin-top: 15%;
	margin-bottom: 15%;
	align-items: center;
	text-align: center;
	font-weight: 900;
}

.error {
	color: #5a5c69;
	font-size: 7rem;
	position: relative;
	line-height: 1;
	width: 12.5rem;
}

@keyframes noise-anim {
	0% {
		clip: rect(50px, 9999px, 13px, 0);
	}
	5% {
		clip: rect(41px, 9999px, 43px, 0);
	}
	10% {
		clip: rect(49px, 9999px, 39px, 0);
	}
	15% {
		clip: rect(95px, 9999px, 21px, 0);
	}
	20% {
		clip: rect(82px, 9999px, 74px, 0);
	}
	25% {
		clip: rect(6px, 9999px, 27px, 0);
	}
	30% {
		clip: rect(35px, 9999px, 19px, 0);
	}
	35% {
		clip: rect(94px, 9999px, 21px, 0);
	}
	40% {
		clip: rect(82px, 9999px, 79px, 0);
	}
	45% {
		clip: rect(35px, 9999px, 65px, 0);
	}
	50% {
		clip: rect(79px, 9999px, 60px, 0);
	}
	55% {
		clip: rect(58px, 9999px, 2px, 0);
	}
	60% {
		clip: rect(41px, 9999px, 90px, 0);
	}
	65% {
		clip: rect(32px, 9999px, 50px, 0);
	}
	70% {
		clip: rect(39px, 9999px, 85px, 0);
	}
	75% {
		clip: rect(13px, 9999px, 87px, 0);
	}
	80% {
		clip: rect(30px, 9999px, 79px, 0);
	}
	85% {
		clip: rect(82px, 9999px, 89px, 0);
	}
	90% {
		clip: rect(80px, 9999px, 65px, 0);
	}
	95% {
		clip: rect(53px, 9999px, 18px, 0);
	}
	100% {
		clip: rect(9px, 9999px, 73px, 0);
	}
}
.error:after {
	content: attr(data-text);
	position: absolute;
	left: 2px;
	text-shadow: -1px 0 #e74a3b;
	top: 0;
	color: #5a5c69;
	background: #f8f9fc;
	overflow: hidden;
	clip: rect(0, 900px, 0, 0);
	animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
	0% {
		clip: rect(90px, 9999px, 89px, 0);
	}
	5% {
		clip: rect(93px, 9999px, 32px, 0);
	}
	10% {
		clip: rect(65px, 9999px, 100px, 0);
	}
	15% {
		clip: rect(31px, 9999px, 19px, 0);
	}
	20% {
		clip: rect(74px, 9999px, 19px, 0);
	}
	25% {
		clip: rect(3px, 9999px, 64px, 0);
	}
	30% {
		clip: rect(75px, 9999px, 54px, 0);
	}
	35% {
		clip: rect(26px, 9999px, 87px, 0);
	}
	40% {
		clip: rect(92px, 9999px, 40px, 0);
	}
	45% {
		clip: rect(67px, 9999px, 19px, 0);
	}
	50% {
		clip: rect(75px, 9999px, 30px, 0);
	}
	55% {
		clip: rect(100px, 9999px, 40px, 0);
	}
	60% {
		clip: rect(90px, 9999px, 26px, 0);
	}
	65% {
		clip: rect(51px, 9999px, 56px, 0);
	}
	70% {
		clip: rect(65px, 9999px, 22px, 0);
	}
	75% {
		clip: rect(31px, 9999px, 8px, 0);
	}
	80% {
		clip: rect(17px, 9999px, 16px, 0);
	}
	85% {
		clip: rect(36px, 9999px, 23px, 0);
	}
	90% {
		clip: rect(97px, 9999px, 70px, 0);
	}
	95% {
		clip: rect(9px, 9999px, 71px, 0);
	}
	100% {
		clip: rect(26px, 9999px, 99px, 0);
	}
}
.error:before {
	content: attr(data-text);
	position: absolute;
	left: -2px;
	text-shadow: 1px 0 #4e73df;
	top: 0;
	color: #5a5c69;
	background: #f8f9fc;
	overflow: hidden;
	clip: rect(0, 900px, 0, 0);
}
/*--- Drop-down Menu -- */

/* .dropdown-menu,
.dropdown-item {
	font-size: 0.9rem;
	font-weight: 700 !important;
	background-color: transparent !important;
	border: 0;
	color: white;
} */

.dropdown-menu.show {
	margin-top: 0.75rem !important;
	border-radius: 0;
	transition: background-color 0.5s ease 0s;
}

.dropdown-submenu {
	transition: background-color 0.5s ease 0s;
}

.dropdown-menu.no-space-top {
	margin-top: 0 !important;
}

.dropdown-menu:hover,
.dropdown-item:hover,
.dropdown-menu:focus,
.dropdown-item:focus,
.dropdown-toggle:active,
.dropdown-toggle:focus {
	color: #ff5a5f !important;
}

.dropdown-submenu.solid,
.dropdown-menu.show.solid {
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
	background: rgba(0, 0, 0, 0.2) !important;
	/* background-color: white; */
	transition: background-color 1s ease 0s;
}

.dropdown-submenu.solid-toggle,
.dropdown-menu.show.solid-toggle {
	/* background: rgba(0, 0, 0, 0.7)!important; */
	background-color: #17bebb !important;
	transition: background-color 1s ease 0s;
}

.dropdown-submenu > .dropdown-menu {
	width: 100%;
	margin-top: 0 !important;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle {
	white-space: nowrap;
}
.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	/* color: #858796; */
	text-align: left;
	list-style: none;
	background-clip: padding-box;
	border: 1px solid #e3e6f0;
	border-radius: 0.35rem;
	font-weight: 700 !important;
	/* background-color: transparent !important; */
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	color: #3a3b45;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	font-weight: 700 !important;
	font-size: 0.9rem;
}
.dropdown-menu-left {
	right: auto;
	left: 0;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

@media (min-width: 576px) {
	.dropdown-menu-sm-left {
		right: auto;
		left: 0;
	}

	.dropdown-menu-sm-right {
		right: 0;
		left: auto;
	}
}
@media (min-width: 768px) {
	.dropdown-menu-md-left {
		right: auto;
		left: 0;
	}

	.dropdown-menu-md-right {
		right: 0;
		left: auto;
	}
}
@media (min-width: 992px) {
	.dropdown-menu-lg-left {
		right: auto;
		left: 0;
	}

	.dropdown-menu-lg-right {
		right: 0;
		left: auto;
	}
}
@media (min-width: 1200px) {
	.dropdown-menu-xl-left {
		right: auto;
		left: 0;
	}

	.dropdown-menu-xl-right {
		right: 0;
		left: auto;
	}
}
.dropup .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropright .dropdown-menu {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropright .dropdown-toggle::after {
	vertical-align: 0;
}

.dropleft .dropdown-menu {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
}
.dropleft .dropdown-toggle::after {
	display: none;
}
.dropleft .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
	right: auto;
	bottom: auto;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #eaecf4;
}

/* .dropdown-item:hover, .dropdown-item:focus {
  color: #2e2f37;
  text-decoration: none;
  background-color: #f8f9fc;
} */
.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #17bebb;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #858796;
	pointer-events: none;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 1.5rem;
	color: #858796;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #3a3b45;
}

.dropdown .dropdown-menu {
	font-size: 0.85rem;
}
.dropdown .dropdown-menu .dropdown-header {
	font-weight: 800;
	font-size: 0.65rem;
	color: #b7b9cc;
}

.dropdown.no-arrow .dropdown-toggle::after {
	display: none;
}
.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}

/* ================ Fim de Dropdown ====================== */

/*============= LANDING PAGE =============*/

/*--- Fixed Landing Page Section --*/

.landing {
	position: relative;
	width: 100%;
	height: 100vh;
	display: table;
	z-index: -1;
}

.home-wrap {
	clip: rect(0, auto, auto, 0);
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
}

.home-inner {
	background-image: url('../img/header_v3.png');
	position: fixed;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center center;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	will-change: transform;
	display: table;
}

/*-- Landing Page Caption --*/

.caption {
	z-index: 1;
	position: absolute;
	top: 25%;
	width: 100%;
	max-width: 100%;
}

.caption svg {
	padding-bottom: 1rem;
}

.caption h3 {
	font-size: 2.5rem;
	padding-bottom: 1rem;
}

.caption h4 {
	padding-bottom: 2rem;
	/* text-shadow: 0.1rem 0.1rem 0.8rem black; */
}

.caption .btn-lg {
	border-width: medium;
	padding: 1rem 1.5rem;
	font-size: 1.1rem;
	border-color: white;
}

/*-- Bouncing Down Arrow --*/

.arrow {
	position: absolute;
	bottom: 0;
	width: 100%;
	animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
}

.down-arrow .fas {
	color: #087e8b;
	font-size: 2.5rem;
	opacity: 0.5;
}

.down-arrow .fas:hover {
	opacity: 0.8;
}

/*============= Vamos Começar SECTION =============*/

/*===== FIXED BACKGROUND IMG DARK =====*/

/*--- iOS Fixed Background Image --*/

/*============= Como funciona SECTION =============*/

.comofunciona-item {
	background-color: #f8fbfb;
	height: 100%;
	padding: 1.6rem;
	border-top: 0.25rem solid #74d7d7;
	box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.12);
	transition: transform 0.7s ease;
	position: relative;
	overflow: hidden;
}

.comofunciona-item img {
	width: 50%;
}

.comofunciona-item:hover {
	transform: scale(0.95);
}

/*============= Footer SECTION =============*/

ul.social {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 1.2rem;
}

ul.social li {
	display: inline;
	padding-right: 0.8rem;
}

ul.social li a {
	color: #17bebb;
	transition: all 0.2s ease;
}

ul.social li a:hover {
	color: #ff5a5f;
}

/*============= Footer SECTION =============*/

.logo-beganinha-footer {
	width: 150px;
	height: 50px;
}

/*============= TOP SCROLL =============*/

.top-scroll {
	right: 1.2rem;
	bottom: 1.2rem;
	position: fixed;
	z-index: 1000;
	display: none;
}

.top-scroll i {
	/* background: white; */
	color: #ff5a5f;
	height: 2rem;
}

/*============= BACKGROUNDS GRADIENTES  =============*/

.bg-gradient-primary {
	background-color: #17bebb;
	background-image: linear-gradient(260deg, #17bebb 10%, #0f7a78 100%);
	background-size: cover;
}

.bg-gradient-secondary {
	background-size: cover;
	background-color: #ff5a5f;
	background-image: linear-gradient(180deg, #ff5a5f 10%, #ff0e15 100%);
	height: auto;
}

.bg-gradient-success {
	background-color: #affc41;
	background-image: linear-gradient(180deg, #affc41 10%, #8ded04 100%);
	background-size: cover;
}

.bg-gradient-info {
	background-color: #ef5054;
	background-image: linear-gradient(180deg, #ef5054 10%, #de1419 100%);
	background-size: cover;
}

.bg-gradient-warning {
	background-color: #f40e0e;
	background-image: linear-gradient(180deg, #f40e0e 10%, #ae0808 100%);
	background-size: cover;
}

.bg-gradient-danger {
	background-color: #e74a3b;
	background-image: linear-gradient(180deg, #e74a3b 10%, #be2617 100%);
	background-size: cover;
}

.bg-gradient-light {
	background-color: #74d7d7;
	background-image: linear-gradient(180deg, #74d7d7 10%, #39c6c6 100%);
	background-size: cover;
	height: 100%;
	width: 100%;
}

.bg-gradient-dark {
	background-color: #087e8b;
	background-image: linear-gradient(180deg, #087e8b 10%, #043c43 100%);
	background-size: cover;
	height: 100%;
	width: 100%;
}

/*============= FIM de BACKGROUNDS GRADIENTES =============*/

/*============= BACKGROUNDS DOS FORMULARIOS DE CADASTRO, E AUTENTICAÇÃO =============*/

.bg-login-image {
	background: url('../img/login_div_ex4.jpg');
	background-position: center;
	background-size: cover;
	height: 620px;
}

.bg-confirmar-codigo-image {
	background: url('../img/login_div_ex1.jpg');
	background-position: center;
	background-size: cover;
	height: 620px;
}

.bg-register-image {
	background: url('../img/login_div_ex3.jpg');
	background-position: center;
	background-size: cover;
	height: 620px;
}

.bg-esqueci-senha-image {
	/* background: url('https://source.unsplash.com/oWTW-jNGl9I/600x800'); */
	background: url('../img/login_div_ex2_v2.jpg');
	background-position: center;
	background-size: cover;
	height: 620px;
}

/*============= fim de BACKGROUNDS DOS FORMULARIOS DE CADASTRO, E AUTENTICAÇÃO =============*/

/*============= FORMULÁRIOS E BOTÕES =============*/

form.form-be-custom .custom-checkbox.small label {
	line-height: 1.5rem;
}

form.form-be-custom .custom-form-control {
	font-size: 0.8rem;
	border-radius: 10rem;
	padding: 1.5rem 1rem;
}

form.form-be-custom .btn-user {
	font-size: 0.8rem;
	border-radius: 10rem;
	padding: 0.75rem 1rem;
}

form.form-be-custom-retangular .custom-form-control-retangular {
	font-size: 0.8rem;
	padding: 1.5rem 1rem;
}

form.form-be-custom .btn-user {
	font-size: 0.8rem;
	border-radius: 10rem;
	padding: 0.75rem 1rem;
}

.form-be-custom-error {
	color: #ff5a5f;
	font-size: 0.6rem;
}

.form-be-custom-progress {
	background-color: #d8d8d8;
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 30px;
	width: 100%;
}

.form-be-custom-progress-done {
	background: linear-gradient(to left, #17bebb, #c5ebea);
	box-shadow: 0 3px 3px -5px #c5ebea, 0 2px 5px #c5ebea;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
	padding: -2px;
}

.btn-google {
	color: #fff;
	background-color: #ea4335;
	border-color: #fff;
}

.btn-google:hover {
	color: #fff;
	background-color: #e12717;
	border-color: #e6e6e6;
}

.btn-google:focus,
.btn-google.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-google.disabled,
.btn-google:disabled {
	color: #fff;
	background-color: #ea4335;
	border-color: #fff;
}

.btn-google:not(:disabled):not(.disabled):active,
.btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
	color: #fff;
	background-color: #d62516;
	border-color: #dfdfdf;
}

.btn-google:not(:disabled):not(.disabled):active:focus,
.btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook {
	color: #fff;
	background-color: #3b5998;
	border-color: #fff;
}

.btn-facebook:hover {
	color: #fff;
	background-color: #30497c;
	border-color: #e6e6e6;
}

.btn-facebook:focus,
.btn-facebook.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
	color: #fff;
	background-color: #3b5998;
	border-color: #fff;
}

.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
	color: #fff;
	background-color: #2d4373;
	border-color: #dfdfdf;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.o-hidden {
	overflow: hidden !important;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.shadow {
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.form-control {
	display: block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: 0.45rem 0.9rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5;
	color: #838f9c;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}
.form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}
.form-control:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #838f9c;
}
.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #c1c9d0;
	outline: 0;
	box-shadow: none;
}
.form-control::placeholder {
	color: #adb5bd;
	opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control {
	appearance: none;
}

select.form-control:focus::-ms-value {
	color: #838f9c;
	background-color: #fff;
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%;
}

.col-form-label {
	padding-top: calc(0.45rem + 1px);
	padding-bottom: calc(0.45rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.25rem;
	line-height: 1.5;
}

.col-form-label-sm {
	padding-top: calc(0.28rem + 1px);
	padding-bottom: calc(0.28rem + 1px);
	font-size: 0.875rem;
	line-height: 1.5;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.45rem 0;
	margin-bottom: 0;
	font-size: 0.9rem;
	line-height: 1.5;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	height: calc(1.8725rem + 2px);
	padding: 0.28rem 0.8rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.form-control-lg {
	height: calc(2.875rem + 2px);
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
	height: auto;
}

textarea.form-control {
	height: auto;
}

.form-group {
	margin-bottom: 1rem;
}

.form-text {
	display: block;
	margin-top: 0.25rem;
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
	padding-right: 5px;
	padding-left: 5px;
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}

.form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
	color: #98a6ad;
}

.form-check-label {
	margin-bottom: 0;
}

.form-check-inline {
	display: inline-flex;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
	position: static;
	margin-top: 0;
	margin-right: 0.3125rem;
	margin-left: 0;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.75rem;
	color: #affc41;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	line-height: 1.5;
	color: #474d56;
	background-color: rgba(175, 252, 65, 0.9);
	border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #affc41;
	padding-right: 2.25rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23AFFC41' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
	border-color: #affc41;
	box-shadow: 0 0 0 0.2rem rgba(175, 252, 65, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
	padding-right: 2.25rem;
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #affc41;
	padding-right: calc(0.75em + 2.3125rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
			no-repeat right 0.75rem center/8px 10px,
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23AFFC41' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
			#fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
			calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
	border-color: #affc41;
	box-shadow: 0 0 0 0.2rem rgba(175, 252, 65, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
	color: #affc41;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
	color: #affc41;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
	border-color: #affc41;
}
.was-validated
	.custom-control-input:valid:checked
	~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
	border-color: #c4fd73;
	background-color: #c4fd73;
}
.was-validated
	.custom-control-input:valid:focus
	~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(175, 252, 65, 0.25);
}
.was-validated
	.custom-control-input:valid:focus:not(:checked)
	~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
	~ .custom-control-label::before {
	border-color: #affc41;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
	border-color: #affc41;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
	border-color: #affc41;
	box-shadow: 0 0 0 0.2rem rgba(175, 252, 65, 0.25);
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.75rem;
	color: #fa6767;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	line-height: 1.5;
	color: #fff;
	background-color: rgba(250, 103, 103, 0.9);
	border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border-color: #fa6767;
	padding-right: 2.25rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa6767' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa6767' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #fa6767;
	box-shadow: 0 0 0 0.2rem rgba(250, 103, 103, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
	padding-right: 2.25rem;
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #fa6767;
	padding-right: calc(0.75em + 2.3125rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
			no-repeat right 0.75rem center/8px 10px,
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa6767' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa6767' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
			#fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
			calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	border-color: #fa6767;
	box-shadow: 0 0 0 0.2rem rgba(250, 103, 103, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
	color: #fa6767;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
	color: #fa6767;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
	border-color: #fa6767;
}
.was-validated
	.custom-control-input:invalid:checked
	~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
	border-color: #fc9898;
	background-color: #fc9898;
}
.was-validated
	.custom-control-input:invalid:focus
	~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(250, 103, 103, 0.25);
}
.was-validated
	.custom-control-input:invalid:focus:not(:checked)
	~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
	~ .custom-control-label::before {
	border-color: #fa6767;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
	border-color: #fa6767;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
	border-color: #fa6767;
	box-shadow: 0 0 0 0.2rem rgba(250, 103, 103, 0.25);
}

.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}
.form-inline .form-check {
	width: 100%;
}
@media (min-width: 576px) {
	.form-inline label {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
	}
	.form-inline .form-group {
		display: flex;
		flex: 0 0 auto;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 0;
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	.form-inline .form-control-plaintext {
		display: inline-block;
	}
	.form-inline .input-group,
	.form-inline .custom-select {
		width: auto;
	}
	.form-inline .form-check {
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;
		padding-left: 0;
	}
	.form-inline .form-check-input {
		position: relative;
		flex-shrink: 0;
		margin-top: 0;
		margin-right: 0.25rem;
		margin-left: 0;
	}
	.form-inline .custom-control {
		align-items: center;
		justify-content: center;
	}
	.form-inline .custom-control-label {
		margin-bottom: 0;
	}
}

.form-control-light {
	background-color: #f1f3fa !important;
	border-color: #f1f3fa !important;
}

input.form-control[type='color'],
input.form-control[type='range'] {
	min-height: 39px;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.custom-select:invalid:focus,
.form-control:invalid:focus,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.custom-select:valid:focus,
.form-control:valid:focus {
	box-shadow: none !important;
}

select.form-control:not([size]):not([multiple]) {
	height: calc(2.25rem + 2px);
}

select.form-control-sm:not([size]):not([multiple]) {
	height: calc(1.8725rem + 2px);
}

.react-switch {
	vertical-align: middle;
	margin-left: 4px;
}

#holder {
	height: 50px;
}
#label {
	margin-bottom: -50px;
	padding-left: 12px;
	padding-top: 2px;
	position: absolute;
	color: #ccc999;
}
#input {
	height: 100%;
	padding-bottom: -10px;
}

.label-inner {
	margin-bottom: -50px;
	padding-left: 12px;
	padding-top: 2px;
	position: absolute;
	color: #ccc999;
}

.input-label-inner {
	height: 100%;
	padding-bottom: -10px;
}

.custom-control {
	position: relative;
	display: block;
	min-height: 1.35rem;
	padding-left: 1.5rem;
}

.custom-control-inline {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem;
}

.custom-radio .custom-control-label::before {
	border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
	.custom-control-input:disabled:checked
	~ .custom-control-label::before {
	background-color: rgba(83, 109, 230, 0.5);
}
/*============= FIM DE FORMULÁRIOS E BOTÕES =============*/

/*======================== SPINNER =========================*/
#spinner-centro-lg {
	width: 100px;
	height: 100px;
	text-align: center;

	/* pura mágica */
	position: absolute;
	top: 50%; /* posiciona na metade da tela */
	margin-top: -25px; /* e retrocede metade da altura */
	left: 60%; /* posiciona na metade da tela */
	margin-left: -100px; /* e retrocede metade da largura */
}

#spinner-centro-sm {
	text-align: center;
	width: 50px;
	height: 50px;

	/* pura mágica */
	position: absolute;
	top: 50%; /* posiciona na metade da tela */
	margin-top: -25px; /* e retrocede metade da altura */
	left: 60%; /* posiciona na metade da tela */
	margin-left: -100px; /* e retrocede metade da largura */
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 9999;
	display: block;
}

.div-spinner-height {
	min-height: 350px;
}

/*======================== FIM SPINNER ======================*/

/*======================== BOUNCE PRE-LOADER ======================*/

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f1f3fa;
	z-index: 9999;
}

#status {
	width: 80px;
	height: 80px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -40px 0 0 -40px;
}

@keyframes bouncing-loader {
	to {
		opacity: 0.1;
		transform: translate3d(0, -16px, 0);
	}
}
.bouncing-loader {
	display: flex;
	justify-content: center;
}
.bouncing-loader > div {
	width: 13px;
	height: 13px;
	margin: 32px 3px;
	background: #17bebb;
	border-radius: 50%;
	animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
	animation-delay: 0.2s;
	background: #fa6767;
}
.bouncing-loader > div:nth-child(3) {
	animation-delay: 0.4s;
	background: #affc41;
}

/*======================== fim BOUNCE PRE-LOADER ===================*/

/* Devices under 992px (lg) */

@media (max-width: 991.98px) {
	.logo-beganinha {
		width: 300px;
		height: 148px;
	}
	.logo-startman {
		width: 300px;
		height: 148px;
	}
	.caption h1 {
		font-size: 3.5rem;
		letter-spacing: 0.2rem;
		padding-bottom: 0.8rem;
	}
	.caption h3 {
		font-size: 2.5rem;
		padding-bottom: 1.7rem;
	}
	.caption .btn-lg {
		padding: 0.7rem 1.2rem;
		font-size: 1rem;
	}
	.clients img {
		max-width: 50%;
		margin: 0 auto;
		/* centered*/
	}

	.center-minimal-layout {
		margin-top: 10rem;
	}
}

/* Devices under 768px (md) */

@media (max-width: 767.98px) {
	.caption {
		top: 15%;
	}
	.caption h1 {
		font-size: 2.7rem;
		letter-spacing: 0.15rem;
		padding-bottom: 0.5rem;
	}
	.caption h3 {
		font-size: 1.7rem;
		padding-bottom: 1.2rem;
	}
	.caption .btn-lg {
		padding: 0.6rem 1.1rem;
	}
	.top-scroll {
		display: none !important;
	}
	.como-funciona-padding {
		padding-bottom: 1.1rem;
	}
}

/* Devices under 576px (sm) */

@media (max-width: 575.98px) {
	.caption h1 {
		font-size: 1.9rem;
		letter-spacing: 0.1rem;
		padding-bottom: 0.4rem;
	}
	.caption h3 {
		font-size: 1.2rem;
		padding-bottom: 1rem;
	}
	.caption .btn-lg {
		padding: 0.4rem 0.9rem;
		font-size: 0.9rem;
	}
	/* [class*='col-'] {
		padding: 1.6rem;
	} */
}
