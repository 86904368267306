@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/bootstrap/main.css';
@import '~simplebar/dist/simplebar.min.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~overlayscrollbars/css/OverlayScrollbars.min.css';
#wrapper {
	display: flex;
}

#wrapper #content-wrapper {
	background-color: #f8f9fc;
	width: 100%;
	overflow-x: hidden;
}

#wrapper #content-wrapper #content {
	flex: 1 0 auto;
}

.fade {
	transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

@keyframes growIn {
	0% {
		transform: scale(0.9);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.animated--grow-in,
.sidebar .nav-item .collapse {
	animation-name: growIn;
	animation-duration: 200ms;
	animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
		opacity cubic-bezier(0, 1, 0.4, 1);
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.animated--fade-in {
	animation-name: fadeIn;
	animation-duration: 200ms;
	animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.icon-circle {
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.border-left-primary {
	border-left: 0.25rem solid #17bebb !important;
}

.border-bottom-primary {
	border-bottom: 0.25rem solid #17bebb !important;
}

.border-left-secondary {
	border-left: 0.25rem solid #ff5a5f !important;
}

.border-bottom-secondary {
	border-bottom: 0.25rem solid #ff5a5f !important;
}

.border-left-success {
	border-left: 0.25rem solid #affc41 !important;
}

.border-bottom-success {
	border-bottom: 0.25rem solid #affc41 !important;
}

.border-left-info {
	border-left: 0.25rem solid #ef5054 !important;
}

.border-bottom-info {
	border-bottom: 0.25rem solid #ef5054 !important;
}

.border-left-warning {
	border-left: 0.25rem solid #f40e0e !important;
}

.border-bottom-warning {
	border-bottom: 0.25rem solid #f40e0e !important;
}

.border-left-danger {
	border-left: 0.25rem solid #e74a3b !important;
}

.border-bottom-danger {
	border-bottom: 0.25rem solid #e74a3b !important;
}

.border-left-light {
	border-left: 0.25rem solid #74d7d7 !important;
}

.border-bottom-light {
	border-bottom: 0.25rem solid #74d7d7 !important;
}

.border-left-dark {
	border-left: 0.25rem solid #087e8b !important;
}

.border-bottom-dark {
	border-bottom: 0.25rem solid #087e8b !important;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.float-none {
	float: none !important;
}

@media (min-width: 576px) {
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1367px) {
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}

/* ================  Sidebar e topbar ====================== */

.sidebar {
	height: 100%;
}

.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
	width: 1rem;
	text-align: center;
	float: right;
	vertical-align: 0;
	border: 0;
	font-weight: 900;
	content: '';
	font-family: 'Font Awesome 5 Free';
}

.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
	content: '';
}

.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
	position: relative;
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
	position: absolute;
	transform: scale(0.7);
	transform-origin: top right;
	right: 0.25rem;
	margin-top: -0.25rem;
}

.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
	height: 2rem;
	width: 2rem;
}

.topbar {
	height: 4.375rem;
}

.topbar #sidebarToggleTop {
	height: 2.5rem;
	width: 2.5rem;
}

.topbar #sidebarToggleTop:hover {
	background-color: #eaecf4;
}

.topbar #sidebarToggleTop:active {
	background-color: #dddfeb;
}

.topbar .navbar-search {
	width: 25rem;
}

.topbar .navbar-search input {
	font-size: 0.85rem;
	height: auto;
}

.topbar .topbar-divider {
	width: 0;
	border-right: 1px solid #e3e6f0;
	height: calc(4.375rem - 2rem);
	margin: auto 1rem;
}

.topbar .nav-item .nav-link {
	height: 4.375rem;
	display: flex;
	align-items: center;
	padding: 0 0.75rem;
}

.topbar .nav-item .nav-link:focus {
	outline: none;
}

.topbar .nav-item:focus {
	outline: none;
}

.topbar .dropdown {
	position: static;
}

.topbar .dropdown .dropdown-menu {
	width: calc(100% - 1.5rem);
	right: 0.75rem;
}

.topbar .dropdown-list {
	padding: 0;
	border: none;
	overflow: hidden;
}

.topbar .dropdown-list .dropdown-header {
	background-color: #4e73df;
	border: 1px solid #4e73df;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #fff;
}

/* .topbar .dropdown-list .dropdown-header {
	background-color: #4e73df;
	border: 1px solid #4e73df;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #fff;
} */

.topbar .dropdown-list .dropdown-item {
	white-space: normal;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-left: 1px solid #e3e6f0;
	border-right: 1px solid #e3e6f0;
	border-bottom: 1px solid #e3e6f0;
	line-height: 1.3rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image {
	position: relative;
	height: 2.5rem;
	width: 2.5rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
	height: 2.5rem;
	width: 2.5rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
	background-color: #eaecf4;
	height: 0.75rem;
	width: 0.75rem;
	border-radius: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	border: 0.125rem solid #fff;
}

.topbar .dropdown-list .dropdown-item .text-truncate {
	max-width: 10rem;
}

.topbar .dropdown-list .dropdown-item:active {
	background-color: #eaecf4;
	color: #3a3b45;
}

@media (min-width: 576px) {
	.topbar .dropdown {
		position: relative;
	}
	.topbar .dropdown .dropdown-menu {
		width: auto;
		right: 0;
	}
	.topbar .dropdown-list {
		width: 20rem !important;
	}
	.topbar .dropdown-list .dropdown-item .text-truncate {
		max-width: 13.375rem;
	}
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {
	color: #d1d3e2;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
	color: #b7b9cc;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
	color: #858796;
}

.sidebar {
	width: 6.5rem;
	min-height: 100vh;
}

.sidebar .nav-item {
	position: relative;
}

.sidebar .nav-item:last-child {
	margin-bottom: 1rem;
}

.sidebar .nav-item .nav-link {
	text-align: center;
	padding: 0.75rem 1rem;
	width: 6.5rem;
}

.sidebar .nav-item .nav-link span {
	font-size: 0.65rem;
	display: block;
}

.sidebar .nav-item.active .nav-link {
	font-weight: 700;
}

.sidebar .nav-item .collapse {
	position: absolute;
	left: calc(6.5rem + 1.5rem / 2);
	z-index: 1;
	top: 2px;
}

.sidebar .nav-item .collapse .collapse-inner {
	border-radius: 0.35rem;
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.sidebar .nav-item .collapsing {
	display: none;
	transition: none;
}

.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
	padding: 0.5rem 0;
	min-width: 10rem;
	font-size: 0.85rem;
	margin: 0 0 1rem 0;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
	margin: 0;
	white-space: nowrap;
	padding: 0.5rem 1.5rem;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 0.65rem;
	color: #b7b9cc;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
	padding: 0.5rem 1rem;
	margin: 0 0.5rem;
	display: block;
	color: #3a3b45;
	text-decoration: none;
	border-radius: 0.35rem;
	white-space: nowrap;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
	background-color: #eaecf4;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
	background-color: #dddfeb;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
	color: #4e73df;
	font-weight: 700;
}

.sidebar #sidebarToggle {
	width: 2.5rem;
	height: 2.5rem;
	text-align: center;
	margin-bottom: 1rem;
	cursor: pointer;
}

.sidebar #sidebarToggle::after {
	font-weight: 900;
	content: '';
	font-family: 'Font Awesome 5 Free';
	margin-right: 0.1rem;
}

.sidebar #sidebarToggle:hover {
	text-decoration: none;
}

.sidebar #sidebarToggle:focus {
	outline: none;
}

.sidebar.toggled {
	width: 0 !important;
	overflow: hidden;
}

.sidebar.toggled #sidebarToggle::after {
	content: '';
	font-family: 'Font Awesome 5 Free';
	margin-left: 0.25rem;
}

.sidebar .sidebar-brand {
	height: 4.375rem;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 800;
	padding: 1.5rem 1rem;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	z-index: 1;
}

.sidebar .sidebar-brand .sidebar-brand-icon i {
	font-size: 2rem;
}

.sidebar .sidebar-brand .sidebar-brand-text {
	display: none;
}

.sidebar hr.sidebar-divider {
	margin: 0 1rem 1rem;
}

.sidebar .sidebar-heading {
	text-align: center;
	padding: 0 1rem;
	font-weight: 800;
	font-size: 0.65rem;
}

@media (min-width: 768px) {
	.sidebar {
		width: 14rem !important;
	}
	.sidebar .nav-item .collapse {
		position: relative;
		left: 0;
		z-index: 1;
		top: 0;
		animation: none;
	}
	.sidebar .nav-item .collapse .collapse-inner {
		border-radius: 0;
		box-shadow: none;
	}
	.sidebar .nav-item .collapsing {
		display: block;
		transition: height 0.15s ease;
	}
	.sidebar .nav-item .collapse,
	.sidebar .nav-item .collapsing {
		margin: 0 1rem;
	}
	.sidebar .nav-item .nav-link {
		display: block;
		width: 100%;
		text-align: left;
		padding: 1rem;
		width: 14rem;
	}
	.sidebar .nav-item .nav-link i {
		font-size: 0.85rem;
		margin-right: 0.25rem;
	}
	.sidebar .nav-item .nav-link span {
		font-size: 0.85rem;
		display: inline;
	}
	.sidebar .nav-item .nav-link[data-toggle='collapse']::after {
		width: 1rem;
		text-align: center;
		float: right;
		vertical-align: 0;
		border: 0;
		font-weight: 900;
		content: '';
		font-family: 'Font Awesome 5 Free';
	}
	.sidebar .nav-item .nav-link[data-toggle='collapse'].collapsed::after {
		content: '';
	}
	.sidebar .sidebar-brand .sidebar-brand-icon i {
		font-size: 2rem;
	}
	.sidebar .sidebar-brand .sidebar-brand-text {
		display: inline;
	}
	.sidebar .sidebar-heading {
		text-align: left;
	}
	.sidebar.toggled {
		overflow: visible;
		width: 6.5rem !important;
	}
	.sidebar.toggled .nav-item .collapse {
		position: absolute;
		left: calc(6.5rem + 1.5rem / 2);
		z-index: 1;
		top: 2px;
		animation-name: growIn;
		animation-duration: 200ms;
		animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
			opacity cubic-bezier(0, 1, 0.4, 1);
	}
	.sidebar.toggled .nav-item .collapse .collapse-inner {
		box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
		border-radius: 0.35rem;
	}
	.sidebar.toggled .nav-item .collapsing {
		display: none;
		transition: none;
	}
	.sidebar.toggled .nav-item .collapse,
	.sidebar.toggled .nav-item .collapsing {
		margin: 0;
	}
	.sidebar.toggled .nav-item:last-child {
		margin-bottom: 1rem;
	}
	.sidebar.toggled .nav-item .nav-link {
		text-align: center;
		padding: 0.75rem 1rem;
		width: 6.5rem;
	}
	.sidebar.toggled .nav-item .nav-link span {
		font-size: 0.65rem;
		display: block;
	}
	.sidebar.toggled .nav-item .nav-link i {
		margin-right: 0;
	}
	.sidebar.toggled .nav-item .nav-link[data-toggle='collapse']::after {
		display: none;
	}
	.sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
		font-size: 2rem;
	}
	.sidebar.toggled .sidebar-brand .sidebar-brand-text {
		display: none;
	}
	.sidebar.toggled .sidebar-heading {
		text-align: center;
	}
}

.sidebar-light .sidebar-brand {
	color: #6e707e;
}

.sidebar-light hr.sidebar-divider {
	border-top: 1px solid #eaecf4;
}

.sidebar-light .sidebar-heading {
	color: #b7b9cc;
}

.sidebar-light .nav-item .nav-link {
	color: #858796;
}

.sidebar-light .nav-item .nav-link i {
	color: #d1d3e2;
}

.sidebar-light .nav-item .nav-link:active,
.sidebar-light .nav-item .nav-link:focus,
.sidebar-light .nav-item .nav-link:hover {
	color: #6e707e;
}

.sidebar-light .nav-item .nav-link:active i,
.sidebar-light .nav-item .nav-link:focus i,
.sidebar-light .nav-item .nav-link:hover i {
	color: #6e707e;
}

.sidebar-light .nav-item .nav-link[data-toggle='collapse']::after {
	color: #b7b9cc;
}

.sidebar-light .nav-item.active .nav-link {
	color: #6e707e;
}

.sidebar-light .nav-item.active .nav-link i {
	color: #6e707e;
}

.sidebar-light #sidebarToggle {
	background-color: #eaecf4;
}

.sidebar-light #sidebarToggle::after {
	color: #b7b9cc;
}

.sidebar-light #sidebarToggle:hover {
	background-color: #dddfeb;
}

.sidebar-dark .sidebar-brand {
	color: #fff;
}

.sidebar-dark hr.sidebar-divider {
	border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.sidebar-dark .sidebar-heading {
	color: rgba(255, 255, 255, 0.4);
}

.sidebar-dark .nav-item .nav-link {
	color: rgba(255, 255, 255, 0.8);
}

.sidebar-dark .nav-item .nav-link i {
	color: rgba(255, 255, 255, 0.3);
}

.sidebar-dark .nav-item .nav-link:active,
.sidebar-dark .nav-item .nav-link:focus,
.sidebar-dark .nav-item .nav-link:hover {
	color: #fff;
}

.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
	color: #fff;
}

.sidebar-dark .nav-item .nav-link[data-toggle='collapse']::after {
	color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .nav-item.active .nav-link {
	color: #fff;
}

.sidebar-dark .nav-item.active .nav-link i {
	color: #fff;
}

.sidebar-dark #sidebarToggle {
	background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark #sidebarToggle::after {
	color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark #sidebarToggle:hover {
	background-color: rgba(255, 255, 255, 0.25);
}

.sidebar-dark.toggled #sidebarToggle::after {
	color: rgba(255, 255, 255, 0.5);
}

body.sidebar-toggled footer.sticky-footer {
	width: 100%;
}

/* ============================= Fim Sidebar e Topbar =========================  */

/* ============================= Texts =========================  */

.text-uppercase,
.sidebar .sidebar-heading,
.dropdown .dropdown-menu .dropdown-header {
	text-transform: uppercase !important;
}

.text-xs {
	font-size: 0.7rem;
}

.text-lg {
	font-size: 1.2rem;
}

.text-gray-100 {
	color: #f8f9fc !important;
}

.text-gray-200 {
	color: #eaecf4 !important;
}

.text-gray-300 {
	color: #dddfeb !important;
}

.text-gray-400 {
	color: #d1d3e2 !important;
}

.text-gray-500 {
	color: #b7b9cc !important;
}

.text-gray-600 {
	color: #858796 !important;
}

.text-gray-700 {
	color: #6e707e !important;
}

.text-gray-800 {
	color: #5a5c69 !important;
}

.text-gray-900 {
	color: #3a3b45 !important;
}

/* ============================= Fim Texts =========================  */

/* ============================= Hyper Template =========================  */

.header-title {
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	font-size: 0.875rem;
	margin-top: 0;
}

.dash-item-overlay {
	position: absolute;
	left: 8%;
	max-width: 350px;
	padding: 20px;
	z-index: 1;
}

/* =============================  Backgrounds =========================  */

.bg-primary-lighten {
	background-color: rgba(23, 190, 187, 0.25) !important;
}

.bg-secondary-lighten {
	background-color: rgba(255, 90, 95, 0.25) !important;
}

.bg-success-lighten {
	background-color: rgba(175, 252, 65, 0.25) !important;
}

.bg-info-lighten {
	background-color: rgba(239, 80, 84, 0.25) !important;
}

.bg-warning-lighten {
	background-color: rgba(244, 14, 14, 0.25) !important;
}

.bg-danger-lighten {
	background-color: rgba(250, 103, 103, 0.25) !important;
}

.bg-light-lighten {
	background-color: rgba(116, 215, 215, 0.25) !important;
}

.bg-dark-lighten {
	background-color: rgba(8, 126, 139, 0.25) !important;
}

/* =============================  fim Backgrounds =========================  */

/* =============================  Charts =========================  */

.chart-content-bg {
	background-color: #f9f9fd;
}

.chart-content-border {
	border: 1px solid #eef2f7;
}

.chart-widget-list p {
	border-bottom: 1px solid #f1f3fa;
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;
}

.apex-charts {
	min-height: 10px !important;
	padding: 0;
}

.apex-charts text {
	font-family: 'Nunito', sans-serif !important;
	fill: #adb5bd;
}

.apex-charts .apexcharts-canvas {
	margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
	font-family: 'Nunito', sans-serif !important;
}

.apexcharts-legend-series {
	font-weight: 600;
}

.apexcharts-gridline {
	pointer-events: none;
	stroke: #f9f9fd;
}

.apexcharts-legend-text {
	color: #98a6ad !important;
	font-family: 'Nunito', sans-serif !important;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
	font-family: 'Nunito', sans-serif !important;
	fill: #adb5bd;
}

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
	fill: #fff;
}

.apexcharts-radar-series polygon {
	fill: transparent;
	stroke: #dee2e6;
}

.apexcharts-radar-series line {
	stroke: #dee2e6;
}

.apexcharts-pie-label,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
	fill: #fff !important;
}

.apexcharts-datalabels-group text {
	fill: #adb5bd !important;
}

.scatter-images-chart .apexcharts-legend {
	overflow: hidden !important;
	min-height: 17px;
}

.scatter-images-chart .apexcharts-legend-marker {
	background: none !important;
	margin-right: 7px !important;
}

.scatter-images-chart .apexcharts-legend-series {
	-webkit-box-align: start !important;
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}

.apexcharts-pie-series path {
	stroke: transparent !important;
}

.apexcharts-track path {
	stroke: #edeff1;
}

.britechart,
.tick text {
	font-family: 'Nunito', sans-serif;
	font-size: 0.75rem;
}

.horizontal-grid-line,
.vertical-grid-line,
.extended-x-line,
.extended-y-line {
	stroke: #dee2e6;
}

.tick text,
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value {
	fill: #98a6ad;
}

.fc-view {
	margin-top: 30px;
}

.none-border .modal-footer {
	border-top: none;
}

.fc-toolbar {
	margin: 15px 0 5px 0;
}

.fc-toolbar h2 {
	font-size: 1.25rem;
	line-height: 1.875rem;
	text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
	font-weight: 700;
}

.fc-day {
	background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}

.fc th.fc-widget-header {
	background: #f8fafc;
	font-size: 13px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f1f3fa;
}

.fc-button {
	background: #f8fafc;
	border: none;
	color: #adb5bd;
	text-shadow: none;
	text-transform: capitalize;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 3px;
	margin: 0 3px;
	padding: 6px 12px;
	height: auto;
}

.fc-text-arrow {
	font-family: inherit;
	font-size: 1rem;
}

.fc-state-hover {
	background: #eef2f7;
}

.fc-state-highlight {
	background: #dee2e6;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #3688fc;
	color: #fff;
	text-shadow: none;
}

.fc-cell-overlay {
	background: #dee2e6;
}

.fc-unthemed td.fc-today {
	background: white;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
	background: #f1f3fa;
}

.fc-event {
	border-radius: 2px;
	border: none;
	cursor: move;
	font-size: 0.8125rem;
	margin: 5px 7px;
	padding: 5px 5px;
	text-align: center;
}

.external-event {
	cursor: move;
	margin: 10px 0;
	padding: 8px 10px;
	color: #fff;
}

.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
}

.fc-basic-view td.fc-day-number {
	padding-right: 8px;
}

.fc-basic-view .fc-content {
	color: #fff;
}

.fc-time-grid-event .fc-content {
	color: #fff;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
	float: right;
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	background-color: #f1f3fa;
	border-radius: 50%;
	margin: 5px;
	font-size: 11px;
}

@media (max-width: 767.98px) {
	.fc-toolbar .fc-left,
	.fc-toolbar .fc-right,
	.fc-toolbar .fc-center {
		float: none;
		display: block;
		clear: both;
		margin: 10px 0;
	}
	.fc .fc-toolbar > * > * {
		float: none;
	}
	.fc-today-button {
		display: none;
	}
}

.chartjs-chart {
	margin: auto;
	position: relative;
	width: 100%;
}

/* ============================= Fim Charts =========================  */

/* ============================= Timeline =========================  */

.timeline-alt {
	padding: 20px 0;
	position: relative;
}

.timeline-alt .timeline-item {
	position: relative;
}

.timeline-alt .timeline-item:before {
	background-color: #f1f3fa;
	bottom: 0;
	content: '';
	left: 9px;
	position: absolute;
	top: 20px;
	width: 2px;
	z-index: 0;
}

.timeline-alt .timeline-item .timeline-icon {
	float: left;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 2px solid transparent;
	font-size: 12px;
	text-align: center;
	line-height: 16px;
	background-color: #fff;
}

.timeline-alt .timeline-item .timeline-item-info {
	margin-left: 30px;
}

.timeline {
	margin-bottom: 50px;
	position: relative;
}

.timeline:before {
	background-color: #dee2e6;
	bottom: 0;
	content: '';
	left: 50%;
	position: absolute;
	top: 30px;
	width: 2px;
	z-index: 0;
}

.timeline-show {
	position: relative;
}

.timeline-show .time-show-name {
	display: inline-block;
	border-radius: 4px;
	background-color: #eef2f7;
	padding: 7px 15px;
}

.timeline-box {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
	box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
	display: block;
	margin: 15px 0;
	position: relative;
	padding: 1.5rem;
	border-radius: 0.25rem;
}

.timeline-album {
	margin-top: 12px;
}

.timeline-album a {
	display: inline-block;
	margin-right: 5px;
}

.timeline-album img {
	height: 36px;
	width: auto;
	border-radius: 3px;
}

@media (min-width: 768px) {
	.timeline .timeline-box {
		margin-left: 45px;
	}
	.timeline .timeline-icon {
		background: #dee2e6;
		border-radius: 50%;
		display: block;
		height: 24px;
		left: -56px;
		margin-top: -12px;
		position: absolute;
		text-align: center;
		top: 50%;
		width: 24px;
	}
	.timeline .timeline-icon i {
		color: #98a6ad;
		font-size: 1rem;
		vertical-align: middle;
	}
	.timeline .timeline-desk {
		display: table-cell;
		vertical-align: top;
		width: 50%;
	}
	.timeline-lg-item {
		display: table-row;
	}
	.timeline-lg-item:before {
		content: '';
		display: block;
		width: 50%;
	}
	.timeline-lg-item .timeline-desk .arrow {
		border-bottom: 12px solid transparent;
		border-right: 12px solid #fff !important;
		border-top: 12px solid transparent;
		display: block;
		height: 0;
		left: -12px;
		margin-top: -12px;
		position: absolute;
		top: 50%;
		width: 0;
	}
	.timeline-lg-item.timeline-item-left:after {
		content: '';
		display: block;
		width: 50%;
	}
	.timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
		border-bottom: 12px solid transparent;
		border-left: 12px solid #fff !important;
		border-top: 12px solid transparent;
		display: block;
		height: 0;
		left: auto;
		margin-top: -12px;
		position: absolute;
		right: -12px;
		top: 50%;
		width: 0;
	}
	.timeline-lg-item.timeline-item-left .timeline-desk .album {
		float: right;
		margin-top: 20px;
	}
	.timeline-lg-item.timeline-item-left .timeline-desk .album a {
		float: right;
		margin-left: 5px;
	}
	.timeline-lg-item.timeline-item-left .timeline-icon {
		left: auto;
		right: -56px;
	}
	.timeline-lg-item.timeline-item-left:before {
		display: none;
	}
	.timeline-lg-item.timeline-item-left .timeline-box {
		margin-right: 45px;
		margin-left: 0;
	}
}

@media (max-width: 767.98px) {
	.timeline .timeline-icon {
		display: none;
	}
}

/* ============================= fim do Timeline =========================  */

/* ============================= Tabs ====================================  */

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
	text-decoration: none;
}

.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
	margin-bottom: -1px;
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #3688fc;
}

.nav-fill .nav-item {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified .nav-item {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
}

.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.nav-tabs > li > a,
.nav-pills > li > a {
	color: #919ca7;
	font-weight: 600;
}

.nav-pills > a {
	color: #919ca7;
	font-weight: 600;
}

.bg-nav-pills {
	background-color: #eef2f7;
}

.nav-tabs.nav-bordered {
	border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}

.nav-tabs.nav-bordered .nav-item {
	margin-bottom: -2px;
}

.nav-tabs.nav-bordered li a {
	border: 0;
	padding: 0.625rem 1.25rem;
}

.nav-tabs.nav-bordered li a.active {
	border-bottom: 2px solid #17bebb;
}

.nav-pills .nav-link {
	border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #17bebb;
}

/* ============================= fim do Tabs =============================  */

/* ============================= Tabelas ====================================  */

.table-centered th,
.table-centered td {
	vertical-align: middle !important;
}

.table .table-user img {
	height: 30px;
	width: 30px;
}

.table .action-icon {
	color: #98a6ad;
	font-size: 1.2rem;
	display: inline-block;
	padding: 0 3px;
}

.table .action-icon:hover {
	color: #919ca7;
}

.table-nowrap th,
.table-nowrap td {
	white-space: nowrap;
}

.table {
	width: 100%;
	margin-bottom: 1.5rem;
	color: #838f9c;
}

.table th,
.table td {
	padding: 0.85rem;
	vertical-align: top;
	border-top: 1px solid #eef2f7;
	font-size: 1em;
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #eef2f7;
}

.table tbody + tbody {
	border-top: 2px solid #eef2f7;
}

.table-sm th,
.table-sm td {
	padding: 0.5rem;
}

.table-bordered {
	border: 1px solid #eef2f7;
}

.table-bordered th,
.table-bordered td {
	border: 1px solid #eef2f7;
}

.table-bordered thead th,
.table-bordered thead td {
	border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
	border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #f1f3fa;
}

.table-hover tbody tr:hover {
	color: #838f9c;
	background-color: #f1f3fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
	background-color: #beedec;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
	border-color: #86dddc;
}

.table-hover .table-primary:hover {
	background-color: #aae7e6;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
	background-color: #aae7e6;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
	background-color: #ffd1d2;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
	border-color: #ffa9ac;
}

.table-hover .table-secondary:hover {
	background-color: #ffb8b9;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
	background-color: #ffb8b9;
}

.table-success,
.table-success > th,
.table-success > td {
	background-color: #e9feca;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
	border-color: #d5fd9c;
}

.table-hover .table-success:hover {
	background-color: #dffeb1;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
	background-color: #dffeb1;
}

.table-info,
.table-info > th,
.table-info > td {
	background-color: #fbcecf;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
	border-color: #f7a4a6;
}

.table-hover .table-info:hover {
	background-color: #f9b6b8;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
	background-color: #f9b6b8;
}

.table-warning,
.table-warning > th,
.table-warning > td {
	background-color: #fcbcbc;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
	border-color: #f98282;
}

.table-hover .table-warning:hover {
	background-color: #fba4a4;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
	background-color: #fba4a4;
}

.table-danger,
.table-danger > th,
.table-danger > td {
	background-color: #fed4d4;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
	border-color: #fcb0b0;
}

.table-hover .table-danger:hover {
	background-color: #fdbbbb;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
	background-color: #fdbbbb;
}

.table-light,
.table-light > th,
.table-light > td {
	background-color: #d8f4f4;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
	border-color: #b7eaea;
}

.table-hover .table-light:hover {
	background-color: #c4eeee;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
	background-color: #c4eeee;
}

.table-dark,
.table-dark > th,
.table-dark > td {
	background-color: #badbdf;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
	border-color: #7fbcc3;
}

.table-hover .table-dark:hover {
	background-color: #a9d2d7;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
	background-color: #a9d2d7;
}

.table-active,
.table-active > th,
.table-active > td {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
	background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
	color: #98a6ad;
	background-color: #474d56;
	border-color: #58606b;
}

.table .thead-light th {
	color: #919ca7;
	background-color: #f1f3fa;
	border-color: #eef2f7;
}

.table-dark {
	color: #98a6ad;
	background-color: #474d56;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
	border-color: #58606b;
}

.table-dark.table-bordered {
	border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.075);
}

.tableFixHead {
	overflow: auto;
	height: 100px;
}
.tableFixHead thead th {
	position: sticky;
	top: 0;
	z-index: 1;
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-sm > .table-bordered {
		border: 0;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-md > .table-bordered {
		border: 0;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-lg > .table-bordered {
		border: 0;
	}
}

@media (max-width: 1366.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-xl > .table-bordered {
		border: 0;
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
	border: 0;
}

.table-fonte-small {
	font-size: 14px !important;
}

.sticky-col {
	position: -webkit-sticky;
	position: sticky;
}

.first-col {
	width: 95px;
	min-width: 90px;
	max-width: 95px;
	left: 0px;
}

.second-col {
	width: 100px;
	min-width: 100px;
	max-width: 120px;
	left: 100px;
}

.fixed-first-row th {
	position: sticky;
	top: 0;
}

/* ============================= fim do tabelas =============================  */

/* ============================= card, badges e accordion =============================  */

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid #eef2f7;
	border-radius: 0.25rem;
}

.card > hr {
	margin-right: 0;
	margin-left: 0;
}

.card > .list-group {
	border-top: inherit;
	border-bottom: inherit;
}

.card > .list-group:first-child {
	border-top-width: 0;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
	border-bottom-width: 0;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.5rem;
}

.card-title {
	margin-bottom: 0.75rem;
}

.card-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-link + .card-link {
	margin-left: 1.5rem;
}

.card-header {
	padding: 0.75rem 1.5rem;
	margin-bottom: 0;
	background-color: #fff;
	border-bottom: 1px solid #eef2f7;
}

.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
	border-top: 0;
}

.card-footer {
	padding: 0.75rem 1.5rem;
	background-color: #fff;
	border-top: 1px solid #eef2f7;
}

.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
	margin-right: -0.75rem;
	margin-bottom: -0.75rem;
	margin-left: -0.75rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -0.75rem;
	margin-left: -0.75rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
	flex-shrink: 0;
	width: 100%;
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
	margin-bottom: 15px;
}

.card-body-customheight {
	max-height: 90% !important;
	height: 70vh !important;
}

@media (min-width: 576px) {
	.card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
	.card-deck .card {
		flex: 1 0 0%;
		margin-right: 15px;
		margin-bottom: 0;
		margin-left: 15px;
	}
}

.card-group > .card {
	margin-bottom: 15px;
}

@media (min-width: 576px) {
	.card-group {
		display: flex;
		flex-flow: row wrap;
	}
	.card-group > .card {
		flex: 1 0 0%;
		margin-bottom: 0;
	}
	.card-group > .card + .card {
		margin-left: 0;
		border-left: 0;
	}
	.card-group > .card:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.card-group > .card:not(:last-child) .card-img-top,
	.card-group > .card:not(:last-child) .card-header {
		border-top-right-radius: 0;
	}
	.card-group > .card:not(:last-child) .card-img-bottom,
	.card-group > .card:not(:last-child) .card-footer {
		border-bottom-right-radius: 0;
	}
	.card-group > .card:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.card-group > .card:not(:first-child) .card-img-top,
	.card-group > .card:not(:first-child) .card-header {
		border-top-left-radius: 0;
	}
	.card-group > .card:not(:first-child) .card-img-bottom,
	.card-group > .card:not(:first-child) .card-footer {
		border-bottom-left-radius: 0;
	}
}

.card-columns .card {
	margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
	.card-columns {
		column-count: 3;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
	}
	.card-columns .card {
		display: inline-block;
		width: 100%;
	}
}

.accordion > .card {
	overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.accordion > .card > .card-header {
	border-radius: 0;
	margin-bottom: -1px;
}

.custom-accordion .card {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.custom-accordion .card-header {
	background-color: #404954;
}

.custom-accordion .card-body {
	border: 1px solid #404954;
}

.custom-accordion .accordion-arrow {
	font-size: 1.2rem;
	position: absolute;
	right: 0;
}

.custom-accordion a.collapsed i.accordion-arrow:before {
	content: '\F142';
}

.custom-accordion-title {
	color: #8391a2;
	position: relative;
}
.custom-accordion-title:hover {
	color: #99a4b2;
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 1rem 0;
	margin-bottom: 1rem;
	list-style: none;
	background-color: transparent;
	border-radius: 0.25rem;
}

.breadcrumb-item {
	display: flex;
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
	display: inline-block;
	padding-right: 0.5rem;
	color: #ced4da;
	content: '';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
	text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
	text-decoration: none;
}

.breadcrumb-item.active {
	color: #adb5bd;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #087e8b;
	background-color: #fff;
	border: 1px solid #dee2e6;
}

.page-link:hover {
	z-index: 2;
	color: #087e8b;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-link:focus {
	z-index: 3;
	outline: 0;
	box-shadow: 0 0 0 0.1rem rgba(23, 190, 187, 0.25);
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #17bebb;
	border-color: #17bebb;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #dee2e6;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
	line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.badge {
		transition: none;
	}
}

a.badge:hover,
a.badge:focus {
	text-decoration: none;
}

.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.badge-pill {
	padding-right: 0.6em;
	padding-left: 0.6em;
	border-radius: 10rem;
}

.badge-primary {
	color: #fff;
	background-color: #17bebb;
}

a.badge-primary:hover,
a.badge-primary:focus {
	color: #fff;
	background-color: #11918e;
}

a.badge-primary:focus,
a.badge-primary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(23, 190, 187, 0.5);
}

.badge-secondary {
	color: #fff;
	background-color: #ff5a5f;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
	color: #fff;
	background-color: #ff272e;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(255, 90, 95, 0.5);
}

.badge-success {
	color: #474d56;
	background-color: #affc41;
}

a.badge-success:hover,
a.badge-success:focus {
	color: #474d56;
	background-color: #9afb0f;
}

a.badge-success:focus,
a.badge-success.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(175, 252, 65, 0.5);
}

.badge-info {
	color: #fff;
	background-color: #ef5054;
}

a.badge-info:hover,
a.badge-info:focus {
	color: #fff;
	background-color: #eb2126;
}

a.badge-info:focus,
a.badge-info.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(239, 80, 84, 0.5);
}

.badge-warning {
	color: #fff;
	background-color: #f40e0e;
}

a.badge-warning:hover,
a.badge-warning:focus {
	color: #fff;
	background-color: #c60909;
}

a.badge-warning:focus,
a.badge-warning.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(244, 14, 14, 0.5);
}

.badge-danger {
	color: #fff;
	background-color: #fa6767;
}

a.badge-danger:hover,
a.badge-danger:focus {
	color: #fff;
	background-color: #f83636;
}

a.badge-danger:focus,
a.badge-danger.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(250, 103, 103, 0.5);
}

.badge-light {
	color: #fff;
	background-color: #74d7d7;
}

a.badge-light:hover,
a.badge-light:focus {
	color: #fff;
	background-color: #4ccccc;
}

a.badge-light:focus,
a.badge-light.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(116, 215, 215, 0.5);
}

.badge-dark {
	color: #fff;
	background-color: #087e8b;
}

a.badge-dark:hover,
a.badge-dark:focus {
	color: #fff;
	background-color: #05525b;
}

a.badge-dark:focus,
a.badge-dark.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(8, 126, 139, 0.5);
}

.badge-primary-lighten {
	color: #17bebb;
	background-color: rgba(23, 190, 187, 0.18);
}

.badge-primary-lighten[href]:hover,
.badge-primary-lighten[href]:focus {
	color: #17bebb;
	text-decoration: none;
	background-color: rgba(23, 190, 187, 0.4);
}

.badge-secondary-lighten {
	color: #ff5a5f;
	background-color: rgba(255, 90, 95, 0.18);
}

.badge-secondary-lighten[href]:hover,
.badge-secondary-lighten[href]:focus {
	color: #ff5a5f;
	text-decoration: none;
	background-color: rgba(255, 90, 95, 0.4);
}

.badge-success-lighten {
	color: #affc41;
	background-color: rgba(175, 252, 65, 0.18);
}

.badge-success-lighten[href]:hover,
.badge-success-lighten[href]:focus {
	color: #affc41;
	text-decoration: none;
	background-color: rgba(175, 252, 65, 0.4);
}

.badge-info-lighten {
	color: #ef5054;
	background-color: rgba(239, 80, 84, 0.18);
}

.badge-info-lighten[href]:hover,
.badge-info-lighten[href]:focus {
	color: #ef5054;
	text-decoration: none;
	background-color: rgba(239, 80, 84, 0.4);
}

.badge-warning-lighten {
	color: #f40e0e;
	background-color: rgba(244, 14, 14, 0.18);
}

.badge-warning-lighten[href]:hover,
.badge-warning-lighten[href]:focus {
	color: #f40e0e;
	text-decoration: none;
	background-color: rgba(244, 14, 14, 0.4);
}

.badge-danger-lighten {
	color: #fa6767;
	background-color: rgba(250, 103, 103, 0.18);
}

.badge-danger-lighten[href]:hover,
.badge-danger-lighten[href]:focus {
	color: #fa6767;
	text-decoration: none;
	background-color: rgba(250, 103, 103, 0.4);
}

.badge-light-lighten {
	color: #74d7d7;
	background-color: rgba(116, 215, 215, 0.18);
}

.badge-light-lighten[href]:hover,
.badge-light-lighten[href]:focus {
	color: #74d7d7;
	text-decoration: none;
	background-color: rgba(116, 215, 215, 0.4);
}

.badge-dark-lighten {
	color: #087e8b;
	background-color: rgba(8, 126, 139, 0.18);
}

.badge-dark-lighten[href]:hover,
.badge-dark-lighten[href]:focus {
	color: #087e8b;
	text-decoration: none;
	background-color: rgba(8, 126, 139, 0.4);
}

.badge-outline-primary {
	color: #17bebb;
	border: 1px solid #17bebb;
	background-color: transparent;
}

.badge-outline-primary[href]:hover,
.badge-outline-primary[href]:focus {
	color: #17bebb;
	text-decoration: none;
	background-color: rgba(23, 190, 187, 0.2);
}

.badge-outline-secondary {
	color: #ff5a5f;
	border: 1px solid #ff5a5f;
	background-color: transparent;
}

.badge-outline-secondary[href]:hover,
.badge-outline-secondary[href]:focus {
	color: #ff5a5f;
	text-decoration: none;
	background-color: rgba(255, 90, 95, 0.2);
}

.badge-outline-success {
	color: #affc41;
	border: 1px solid #affc41;
	background-color: transparent;
}

.badge-outline-success[href]:hover,
.badge-outline-success[href]:focus {
	color: #affc41;
	text-decoration: none;
	background-color: rgba(175, 252, 65, 0.2);
}

.badge-outline-info {
	color: #ef5054;
	border: 1px solid #ef5054;
	background-color: transparent;
}

.badge-outline-info[href]:hover,
.badge-outline-info[href]:focus {
	color: #ef5054;
	text-decoration: none;
	background-color: rgba(239, 80, 84, 0.2);
}

.badge-outline-warning {
	color: #f40e0e;
	border: 1px solid #f40e0e;
	background-color: transparent;
}

.badge-outline-warning[href]:hover,
.badge-outline-warning[href]:focus {
	color: #f40e0e;
	text-decoration: none;
	background-color: rgba(244, 14, 14, 0.2);
}

.badge-outline-danger {
	color: #fa6767;
	border: 1px solid #fa6767;
	background-color: transparent;
}

.badge-outline-danger[href]:hover,
.badge-outline-danger[href]:focus {
	color: #fa6767;
	text-decoration: none;
	background-color: rgba(250, 103, 103, 0.2);
}

.badge-outline-light {
	color: #74d7d7;
	border: 1px solid #74d7d7;
	background-color: transparent;
}

.badge-outline-light[href]:hover,
.badge-outline-light[href]:focus {
	color: #74d7d7;
	text-decoration: none;
	background-color: rgba(116, 215, 215, 0.2);
}

.badge-outline-dark {
	color: #087e8b;
	border: 1px solid #087e8b;
	background-color: transparent;
}

.badge-outline-dark[href]:hover,
.badge-outline-dark[href]:focus {
	color: #087e8b;
	text-decoration: none;
	background-color: rgba(8, 126, 139, 0.2);
}

.widget-flat {
	position: relative;
	overflow: hidden;
}

/* @media (min-width: 1200px) and (max-width: 1500px) {
	.widget-flat i.widget-icon {
		display: none;
	}
} */

.widget-icon {
	color: #17bebb;
	font-size: 20px;
	background-color: rgba(23, 190, 187, 0.25);
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 3px;
	display: inline-block;
}

/* ============================= Fim de card, badges e accordion =============================  */

/* ============================= Datepicker e FullCalendar ====================================  */

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
	margin-left: -8px;
	position: absolute;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker-popper[data-placement^='top']
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
	.react-datepicker__triangle::before {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
}

.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker-popper[data-placement^='top']
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
	.react-datepicker__triangle::before {
	content: '';
	z-index: -1;
	border-width: 8px;
	left: -8px;
	border-bottom-color: #68d4d4;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
	top: 0;
	margin-top: -8px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom']
	.react-datepicker__triangle::before {
	border-top: none;
	border-bottom-color: #fff;
}

.react-datepicker-popper[data-placement^='bottom']
	.react-datepicker__triangle::before {
	top: -1px;
	border-bottom-color: #68d4d4;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
	bottom: 0;
	margin-bottom: -8px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker-popper[data-placement^='top']
	.react-datepicker__triangle::before {
	border-bottom: none;
	border-top-color: #fff;
}

.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker-popper[data-placement^='top']
	.react-datepicker__triangle::before {
	bottom: -1px;
	border-top-color: #68d4d4;
}

.react-datepicker-wrapper {
	display: block;
}

.react-datepicker {
	font-family: 'Nunito', sans-serif;
	font-size: 0.9rem;
	background-color: #fff;
	color: #98a6ad;
	border: 1px solid #68d4d4;
	border-radius: 0.3rem;
	display: inline-block;
	position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
	left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
	border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
	border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
	border-radius: 0.3rem;
}

.react-datepicker__triangle {
	position: absolute;
	left: 50px;
}

.react-datepicker-popper {
	z-index: 1;
}

.react-datepicker-popper[data-placement^='bottom'] {
	margin-top: 10px;
}

.react-datepicker-popper[data-placement^='top'] {
	margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^='right'] {
	margin-left: 8px;
}

.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
	left: auto;
	right: 42px;
}

.react-datepicker-popper[data-placement^='left'] {
	margin-right: 8px;
}

.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
	left: 42px;
	right: auto;
}

.react-datepicker__header {
	text-align: center;
	background-color: #fff;
	border-bottom: 1px solid #68d4d4;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	padding-top: 8px;
	position: relative;
}

.react-datepicker__header--time {
	padding-bottom: 8px;
	padding-left: 5px;
	padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
	display: inline-block;
	margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	color: #98a6ad;
	font-weight: bold;
	font-size: 0.9rem;
}

.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.react-datepicker__navigation {
	background: none;
	line-height: 1.5rem;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 10px;
	width: 0;
	padding: 0;
	border: 0.45rem solid transparent;
	z-index: 1;
	height: 10px;
	width: 10px;
	text-indent: -999em;
	overflow: hidden;
}

.react-datepicker__navigation--previous {
	left: 10px;
	border-right-color: #98a6ad;
}

.react-datepicker__navigation--previous:hover {
	border-right-color: #7c8d96;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
	border-right-color: #b4bfc4;
	cursor: default;
}

.react-datepicker__navigation--next {
	right: 10px;
	border-left-color: #98a6ad;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 80px;
}

.react-datepicker__navigation--next:hover {
	border-left-color: #7c8d96;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
	border-left-color: #b4bfc4;
	cursor: default;
}

.react-datepicker__navigation--years {
	position: relative;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.react-datepicker__navigation--years-previous {
	top: 4px;
	border-top-color: #98a6ad;
}

.react-datepicker__navigation--years-previous:hover {
	border-top-color: #7c8d96;
}

.react-datepicker__navigation--years-upcoming {
	top: -4px;
	border-bottom-color: #98a6ad;
}

.react-datepicker__navigation--years-upcoming:hover {
	border-bottom-color: #7c8d96;
}

.react-datepicker__month-container {
	float: left;
}

.react-datepicker__month {
	margin: 0.4rem;
	text-align: center;
}

.react-datepicker__month .react-datepicker__month-text {
	display: inline-block;
	width: 4rem;
	margin: 2px;
}

.react-datepicker__input-time-container {
	clear: both;
	width: 100%;
	float: left;
	margin: 5px 0 10px 15px;
	text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
	display: inline-block;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container {
	display: inline-block;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input {
	display: inline-block;
	margin-left: 10px;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input {
	width: 85px;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time']::-webkit-inner-spin-button,
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time'] {
	-moz-appearance: textfield;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__delimiter {
	margin-left: 5px;
	display: inline-block;
}

.react-datepicker__time-container {
	float: right;
	border-left: 1px solid #68d4d4;
	width: 100px;
}

.react-datepicker__time-container--with-today-button {
	display: inline;
	border: 1px solid #aeaeae;
	border-radius: 0.3rem;
	position: absolute;
	right: -72px;
	top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
	position: relative;
	background: white;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box {
	width: 100px;
	overflow-x: hidden;
	margin: 0 auto;
	text-align: center;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list {
	list-style: none;
	margin: 0;
	height: calc(195px + (1.5rem / 2));
	overflow-y: scroll;
	padding-right: 0px;
	padding-left: 0px;
	width: 100%;
	box-sizing: content-box;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	height: 30px;
	padding: 5px 10px;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover {
	cursor: pointer;
	background-color: #fff;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: #17bebb;
	color: white;
	font-weight: bold;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected:hover {
	background-color: #17bebb;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled {
	color: #98a6ad;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled:hover {
	cursor: default;
	background-color: transparent;
}

.react-datepicker__week-number {
	color: #98a6ad;
	display: inline-block;
	width: 1.5rem;
	line-height: 1.5rem;
	text-align: center;
	margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
	cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
	border-radius: 0.3rem;
	background-color: #fff;
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #98a6ad;
	display: inline-block;
	width: 1.5rem;
	line-height: 1.5rem;
	text-align: center;
	margin: 0.166rem;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range {
	border-radius: 0.3rem;
	background-color: #17bebb;
	color: #fff;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover {
	background-color: #14a7a5;
}

.react-datepicker__month--disabled {
	color: #98a6ad;
	pointer-events: none;
}

.react-datepicker__month--disabled:hover {
	cursor: default;
	background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text {
	cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
	border-radius: 0.3rem;
	background-color: #fff;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today {
	font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
	border-radius: 0.3rem;
	background-color: #17bebb;
	color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover {
	background-color: #14a7a5;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
	color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
	color: green;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
	border-radius: 0.3rem;
	background-color: #17bebb;
	color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
	background-color: #14a7a5;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
	border-radius: 0.3rem;
	background-color: #24e4e1;
	color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
	background-color: #14a7a5;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range) {
	background-color: rgba(23, 190, 187, 0.5);
}

.react-datepicker__month--selecting-range
	.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range),
.react-datepicker__month--selecting-range
	.react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range) {
	background-color: #fff;
	color: #98a6ad;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
	cursor: default;
	color: #98a6ad;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
	background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover {
	background-color: #17bebb;
}

.react-datepicker__month-text:hover {
	background-color: #fff;
}

.react-datepicker__input-container {
	position: relative;
	display: block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	border: 1px solid transparent;
	border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
	cursor: pointer;
}

.react-datepicker__year-read-view:hover
	.react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover
	.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover
	.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover
	.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
	.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
	.react-datepicker__month-read-view--down-arrow {
	border-top-color: #7c8d96;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-top-color: #98a6ad;
	float: right;
	margin-left: 20px;
	top: 8px;
	position: relative;
	border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: #fff;
	position: absolute;
	width: 50%;
	left: 25%;
	top: 30px;
	z-index: 1;
	text-align: center;
	border-radius: 0.3rem;
	border: 1px solid #68d4d4;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
	cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
	height: 150px;
	overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: #98a6ad;
}

.react-datepicker__year-option:hover
	.react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover
	.react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover
	.react-datepicker__navigation--years-upcoming {
	border-bottom-color: #7c8d96;
}

.react-datepicker__year-option:hover
	.react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover
	.react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover
	.react-datepicker__navigation--years-previous {
	border-top-color: #7c8d96;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
	position: absolute;
	left: 15px;
}

.react-datepicker__close-icon {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	outline: 0;
	padding: 0;
	vertical-align: middle;
	position: absolute;
	height: 16px;
	width: 16px;
	top: 25%;
	right: 7px;
}

.react-datepicker__close-icon::after {
	background-color: #17bebb;
	border-radius: 50%;
	bottom: 0;
	box-sizing: border-box;
	color: #fff;
	content: '×';
	cursor: pointer;
	font-size: 12px;
	height: 16px;
	width: 16px;
	line-height: 1;
	margin: -8px auto 0;
	padding: 2px;
	position: absolute;
	right: 0px;
	text-align: center;
}

.react-datepicker__today-button {
	background: #fff;
	border-top: 1px solid #68d4d4;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	padding: 5px 0;
	clear: left;
}

.react-datepicker__portal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
	width: 3rem;
	line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
	.react-datepicker__portal .react-datepicker__day-name,
	.react-datepicker__portal .react-datepicker__day,
	.react-datepicker__portal .react-datepicker__time-name {
		width: 2rem;
		line-height: 2rem;
	}
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
	font-size: 1.62rem;
}

.react-datepicker__portal .react-datepicker__navigation {
	border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
	border-right-color: #98a6ad;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
	border-right-color: #7c8d96;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal
	.react-datepicker__navigation--previous--disabled:hover {
	border-right-color: #b4bfc4;
	cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
	border-left-color: #98a6ad;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
	border-left-color: #7c8d96;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
	border-left-color: #b4bfc4;
	cursor: default;
}

.fc-view {
	margin-top: 30px;
}

.none-border .modal-footer {
	border-top: none;
}

.fc-toolbar {
	margin: 15px 0 5px 0;
}

.fc-toolbar h2 {
	font-size: 1.25rem;
	line-height: 1.875rem;
	text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
	font-weight: 700;
}

.fc-day {
	background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}

.fc th.fc-widget-header {
	background: #f8fafc;
	font-size: 13px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f1f3fa;
}

.fc-button {
	background: #f8fafc;
	border: none;
	color: #adb5bd;
	text-shadow: none;
	text-transform: capitalize;
	box-shadow: none;
	border-radius: 3px;
	margin: 0 3px;
	padding: 6px 12px;
	height: auto;
}

.fc-button.fc-button-active {
	background-color: #17bebb !important;
}

.fc-button.fc-button-primary:hover {
	color: #fff;
	background-color: #17bebb;
	border-color: #17bebb;
}

.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
	box-shadow: 0 0 0 0.2rem rgba(23, 190, 187, 0.5);
}

.fc-text-arrow {
	font-family: inherit;
	font-size: 1rem;
}

.fc-state-hover {
	background: #eef2f7;
}

.fc-state-highlight {
	background: #dee2e6;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #17bebb;
	color: #fff;
	text-shadow: none;
}

.fc-cell-overlay {
	background: #dee2e6;
}

.fc-unthemed td.fc-today {
	background: white;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
	background: #f1f3fa;
}

.fc-event {
	border-radius: 2px;
	border: none;
	cursor: move;
	font-size: 0.8125rem;
	margin: 5px 7px;
	padding: 5px 5px;
	text-align: center;
	height: 15px !important;
}

.external-event {
	cursor: move;
	margin: 10px 0;
	padding: 8px 10px;
	color: #fff;
}

.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
}

.fc-basic-view td.fc-day-number {
	padding-right: 8px;
}

.fc-basic-view .fc-content {
	color: #fff;
}

.fc-time-grid-event .fc-content {
	color: #fff;
}

.fc-day-number {
	float: right;
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	background-color: #f1f3fa;
	border-radius: 50%;
	margin: 5px;
	font-size: 11px;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
	float: right;
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	background-color: #f1f3fa;
	border-radius: 50%;
	margin: 5px;
	font-size: 11px;
}

@media (max-width: 767.98px) {
	.fc-toolbar .fc-left,
	.fc-toolbar .fc-right,
	.fc-toolbar .fc-center {
		float: none;
		display: block;
		clear: both;
		margin: 10px 0;
	}
	.fc .fc-toolbar > * > * {
		float: none;
	}
	.fc-today-button {
		display: none;
	}
}

/* ============================= fim do Datepicker  e FullCalendar =============================  */

/* ============================= Modal =========================================  */

.modal-open {
	overflow: hidden;
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}

.modal.fade .modal-dialog {
	transition: transform 0.3s ease-out;
	transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}

.modal.show .modal-dialog {
	transform: none;
}

.modal.modal-static .modal-dialog {
	transform: scale(1.02);
}

.modal-dialog-scrollable {
	display: flex;
	max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
	max-height: calc(100vh - 1rem);
	overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
	flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
	display: block;
	height: calc(100vh - 1rem);
	height: min-content;
	content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
	content: none;
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 0 solid transparent;
	border-radius: 0.2rem;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #474d56;
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.7;
}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
	margin: 0.25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem);
	}
	.modal-dialog-scrollable .modal-content {
		max-height: calc(100vh - 3.5rem);
	}
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
	}
	.modal-dialog-centered::before {
		height: calc(100vh - 3.5rem);
		height: min-content;
	}
	.modal-sm {
		max-width: 300px;
	}
}

@media (min-width: 992px) {
	.modal-lg,
	.modal-xl {
		max-width: 800px;
	}
}

@media (min-width: 1367px) {
	.modal-xl {
		max-width: 1140px;
	}
}

.modal-title {
	margin-top: 0;
}

.modal-full-width {
	width: 95%;
	max-width: none;
}

.modal-top {
	margin: 0 auto;
}

.modal-right {
	position: absolute;
	right: 0;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	height: 100%;
	margin: 0;
	background-color: #fff;
	align-content: center;
	transform: translate(25%, 0) !important;
}

.modal-right button.close {
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 1;
}

.modal.show .modal-right {
	transform: translate(0, 0) !important;
}

.modal-bottom {
	display: flex;
	flex-flow: column nowrap;
	-ms-flex-pack: end;
	justify-content: flex-end;
	height: 100%;
	margin: 0 auto;
	align-content: center;
}

.modal-colored-header {
	color: #fff;
	border-radius: 0;
}

.modal-colored-header .close {
	color: #fff !important;
}

.modal-filled {
	color: #fff;
}

.modal-filled .modal-header {
	background-color: rgba(255, 255, 255, 0.07);
}

.modal-filled .modal-header,
.modal-filled .modal-footer {
	border: none;
}

.modal-filled .close {
	color: #fff !important;
}

.customModal-evolucaoObjetivo {
	max-width: 90% !important;
	width: 100%;
	height: 80% !important;
}

.modal-width {
	width: 100% !important;
	max-width: 95% !important;
}
/* ============================= fim do Modal =================================  */

/* ============================= form =================================  */

.eye-slash {
	float: right;
	margin-right: 30px;
	margin-top: -32px;
	position: relative;
	z-index: 2;
}

/* ============================= fim do form =================================  */

/* ============================= Fim Hyper Template =========================  */
